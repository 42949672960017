<template>
  <div class="section-box">
    <div class="section-title">
      <div class="container">
        <router-link
          @click="hidden = !hidden"
          :to="btnurl"
          class="section-link"
        >
          <div class="row">
            <div
              v-if="align == 'left'"
              class="col-12 col-lg-6 text-left order-lg-1"
            >
              <div class="col-8 col-lg-12 m-auto">
                <img
                  :src="require('@/assets/img/all/' + imgurl + '.png')"
                  :alt="h2text"
                  class="img-fluid h2-icon h2-icon-left"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 text-left order-2">
              <br />
              <h2 class="text-center m-auto w-100">
                <span v-html="h2text"></span>
              </h2>
              <br />
              <small
                class="h2-small"
                v-if="h2small != ''"
                v-html="h2small"
              ></small>
              <br />
              <p v-html="ptext1"></p>
              <p v-html="ptext2"></p>
              <div class="d-grid col-lg-8 m-auto">
                <div class="btn btn-rainbow mt-lg-5 pt-4 pb-4">
                  <span v-html="btntext"></span>
                </div>
              </div>
            </div>
            <div
              v-if="align == 'right'"
              class="col-12 col-lg-6 text-left order-lg-3"
            >
              <div class="col-8 col-lg-12 m-auto">
                <img
                  :src="require('@/assets/img/all/' + imgurl + '.png')"
                  :alt="h2text"
                  class="img-fluid h2-icon h2-icon-right"
                />
              </div>
            </div>
          </div>
          <div v-if="createdate != ''" class="row">
            <div class="col-12 col-lg-6 text-center text-lg-end">
              <span v-html="createdate"></span>
            </div>
            <div class="col-12 col-lg-6 text-center text-lg-start">
              <ul class="list-tags list-unstyled list-inline">
                <li class="list-inline-item">
                  <router-link to="/blog/tanfolyam" v-html="tag1"></router-link>
                </li>
                <li class="list-inline-item">
                  <router-link
                    to="/szolgaltatasaink/frontend-development"
                    v-html="tag2"
                  >
                  </router-link>
                </li>
                <li class="list-inline-item">
                  <router-link
                    to="/szolgaltatasaink/frontend-development/vuejs"
                    v-html="tag3"
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    align: String,
    icon: String,
    imgurl: String,
    h2text: String,
    h2small: String,
    ptext1: String,
    ptext2: String,
    createdate: String,
    btntext: String,
    btnurl: String,
    tag1: String,
    tag1url: String,
    tag2: String,
    tag2url: String,
    tag3: String,
    tag3url: String,
  },
};
</script>
