<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <section-title
        icon="icon-products"
        h2="Dobozos termékeink segítségével fejlesztései könnyebben és <br class='d-none d-lg-block'> gyorsabban megvalósulhatnak."
        h2small="2021-ben az internetes jelenlét szükségszerű"
        createdate=""
      />
    </div>
    <div class="section section-hero-body section-hero-services">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <small class="h2-small">
              2008-tól, a Magento e-commerce megjelenésével, időnket a Magento
              webáruház motor megismerésére fordítotjuk. A legsikeresebb
              termékünk a folyamatosan frissülő és karbantartott magyar nyelvi
              csomag Magento Open Source 2.x és Magento Open Source 1.x
              webáruházakhoz.
            </small>

            <div class="box-contents">
              <h3>Termékeink</h3>
              <br />
              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="/termekeink/magento-2-magyar-nyelvi-modul"
              >
                <span>// Magento 2.x magyar nyelvi csomag</span>
              </router-link>
              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="/termekeink/magento-1-magyar-nyelvi-modul"
              >
                <span>// Magento 1.x magyar nyelvi csomag</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <img
              src="@/assets/img/all/img-box-2021-left.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-products"
    h2="Termékeink"
    h2small="Ismerje meg termékeinket!"
    createdate=""
  />

  <SectionBox
    align="left"
    icon="icon-products"
    imgurl="img-box-products-magento-1x-right"
    h2text="Magyarul kommunikáló <br class='d-none d-lg-block'> Magento Open Source 2.x webáruház?"
    h2small="A magyar csomag segítségével a Magento Open Source 2.x webáruház jól illeszkedik a magyarországi környezetbe."
    ptext1="Társalgási szinten a magyarok csak 20%-a beszél angolul és a magyar emberek 65%-a semmilyen idegen nyelven nem tud kommunikálni. Ez a legrosszabb teljesítmény az Európai Unióban. Ne veszítsen potenciális vásárlókat, csak azért, mert nem magyar nyelvű vagy magyartalan a Magento webáruháza!"
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/termekeink/magento-2-magyar-nyelvi-modul"
    tag1=""
    tag1url=""
    tag2=""
    tag2url=""
    tag3=""
    tag3url=""
  />

  <SectionBox
    align="right"
    class="mb-4"
    icon="icon-products"
    imgurl="img-box-products-magento-1x-left"
    h2text="Magyarul kommunikáló <br class='d-none d-lg-block'> Magento Open Source 1.x webáruház?"
    h2small="A magyar csomag segítségével a Magento Open Source 1.x webáruház jól illeszkedik a magyarországi környezetbe."
    ptext1="Társalgási szinten a magyarok csak 20%-a beszél angolul és a magyar emberek 65%-a semmilyen idegen nyelven nem tud kommunikálni. Ez a legrosszabb teljesítmény az Európai Unióban. Ne veszítsen potenciális vásárlókat, csak azért, mert nem magyar nyelvű vagy magyartalan a Magento webáruháza!"
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/termekeink/magento-1-magyar-nyelvi-modul"
    tag1=""
    tag1url=""
    tag2=""
    tag2url=""
    tag3=""
    tag3url=""
  />
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import SectionBox from "@/components/SectionBox.vue";

export default {
  components: {
    SectionTitle,
    SectionBox,
  },
  data() {
    return {};
  },
};
</script>
